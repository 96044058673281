@import "../../styles/styles.scss";

.test {

    &__form {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        width: fit-content;
        height: fit-content;
        margin: 50px auto 0 auto;
    }

    &__login-title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
    }
}