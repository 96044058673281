@import "../../styles/styles.scss";

.reviews {
    &__blocks {
        display: flex;
        justify-content: space-between;
        gap: 40px;
    }

    &__block {
        border: 1px solid $color_light_lines;
        border-radius: 10px;
        padding: 30px;
        width: 30%;

        h3 {
            text-align: center;
            font-size: 23px;
            font-weight: 600;
            letter-spacing: 0.6px;
            margin-bottom: 20px;
        }

        p {
            font-size: 17px;
            line-height: 30px;
        }
    }
}



@media (max-width: $lg) {
    .reviews {
        &__blocks {
            display: flex;
            flex-direction: column;
        }

        &__block {
            margin-bottom: 30px;
            width: 100%;
        }

        h3 {
            font-size: 20px;
        }

        p {
            font-size: 16px;
        }
    }
}

@media (max-width: $xs) {
    .reviews {
        &__blocks {
            gap: 10px;
        }
        &__block:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}