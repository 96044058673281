@import "../../styles/styles.scss";

.test {
    padding-top: 70px;
    padding-bottom: 40px;
    &__title {
        color: $color_secondary;
        font-size: 19px;
        font-weight: 600;
        margin-bottom: 30px;
        text-align: left;
    }

    &__tasks {
        border: 2px solid $color_secondary;
        border-radius: 19px;
        padding: 15px 30px;
        margin-bottom: 50px;
    }

    &__levelview {
        margin-top: 70px;
    }

    &__tasks-row {
        display: flex;
        gap: 15px;
        justify-content: space-between;
        align-items: center;
    }

    &__tasks-item img {
        object-fit: contain;
        width: 100px;
    }

    &__logo img {
        object-fit: contain;
        width: 78px;
        border-radius: 5px;
    }

    &__levels {
        padding-top: 70px;
        display: flex;
        gap: 60px;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__level img {
        object-fit: contain;
        width: 300px;
        margin-bottom: 30px;
        border-radius: 10px;
    }

    &__level-not-active {
        filter: brightness(45%);
    }

    &__testview {
        margin-top: 50px;
    }

    &__header-text {
        margin-top: 40px;
        font-size: 20px;
        font-weight: 600;
        text-align: left;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        gap: 60px;
    }

    &__item img {
        object-fit: contain;
        width: 450px;
        margin-bottom: 20px;
    }

    &__item {
        display: flex;
        flex-direction: column;
    }

    &__button {
        @include primary-button(16px);
        width: fit-content;
        height: fit-content;
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
    }

    &__accordion p {
        color: $color_secondary;
        font-size: 19px;
        font-weight: 600;
        text-align: left;
    }

    &__video {
        margin-top: 20px;
        img {
            display: block;
            margin: auto;
            object-fit: contain;
            width: 70%;
            border-radius: 10px;
        }
    }

    &__empty {
        color: $color_danger;
        font-weight: 500;
        margin-top: 20px;

        &_main {
            display: block;
            margin: 40px auto 0 auto;
        }
    }

    &__result-img {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        img {
            margin: auto;
            object-fit: contain;
            width: 60%;
            border-radius: 8px;
        }
    }

    &__certificate {
        display: flex;
        gap: 40px;
        align-items: center;
        flex-wrap: wrap;
    }

    &__certificate-btn {
        @include primary-button(16px);
        width: fit-content;
        height: fit-content;
        // margin-top: 50px;
        // margin-left: auto;
        // margin-right: auto;
    }

    &__video-media {
        margin: 20px auto;
    }

    &__video-lessons {
        font-weight: 500;
        color: $color_secondary;
        font-size: 17px;
        margin: 20px 0;
    }

    &__text-img {
        margin: 10px auto;
        img {
            object-fit: contain;
            display: block;
            margin: auto;
            width: 70%;
            border-radius: 5px;
        }
    }

    &__text-link {
        text-decoration: underline;
        font-weight: 500;
        color: $color_secondary;
    }

    &__text-img-3 {
        width: 50%;
    }


    &__md-editor {
        color: $color_secondary !important;
        font-family: $font_main !important;

        p, span {
            color: $color_secondary !important;
            font-family: $font_main !important;
        }

        img {
            display: block;
            margin: auto;
            width: 50%;
            max-width: 50% !important;
        }

        ol, li {
            color: $color_secondary !important;
        }
    }

    &__logout {
        @include primary-button(16px);
    }

    &__teacher-header {
        display: block;
        font-weight: 600;
        color: $color_secondary;
        font-size: 17px;
        margin-top: 40px;
        margin-bottom: 0;
    }

    &__start-btn {
        @include primary-button(16px);
        width: fit-content;
        margin-top: 30px;
        display: block;
    }
}

video.MuiCardMedia-root {
    width: 70% !important;
}

.MuiAccordionDetails-root p {
    font-weight: 400;
    color: $color_secondary;
    font-size: 17px;
}

.load {
    margin: auto;
    margin-top: 30px;
}

.MuiFormControlLabel-label {
    color: #fff;
}

@media (max-width: 1000px) {
    .test {
        &__tasks-item img {
            width: 40px;
        }
        &__logo img {
            width: 30px;
        }

        &__video {
            img {
                width: 100%;
                border-radius: 5px;
            }
        }
    }
}

@media (max-width: 700px) {
    .test {
        &__levels {
            gap: 30px;
        }
        &__level img {
            width: 200px;
            margin-bottom: 10px;
            border-radius: 10px;
        }
    }
}

@media (max-width: 500px) {
    .test {
        padding-top: 50px;
        padding-bottom: 30px;    
        &__levels {
            padding-top: 40px;
            justify-content: center;
        }
        &__tasks {
            border: 2px solid $color_secondary;
            border-radius: 10px;
            padding: 10px;
        }
        &__tasks-row {
            display: flex;
            gap: 8px;
            justify-content: space-between;
            align-items: center;
        }
        &__tasks-item img {
            width: 40px;
        }
        &__logo img {
            width: 30px;
        }

        &__title {
            font-size: 17px;
        }

        &__levelview {
            margin-top: 40px;
        }

        &__item img {
            object-fit: contain;
            width: 300px;
        }

        &__accordion p {
            font-size: 17px;
        }    

        &__empty {
            font-size: 15px;
        } 
        
        &__result-img {
            img {
                margin: auto;
                object-fit: contain;
                width: 100%;
            }
        }
        
        &__text-img-3 {
            width: 100%;
        }

        &__md-editor {            
            img {
                width: 100%;
                max-width: 100% !important;
            }    
        }    
    }
    video.MuiCardMedia-root {
        width: 100% !important;
    }
}

.MuiPaper-elevation1,
.MuiAccordion-root,
.MuiAccordionSummary-root {
    background-color: #000;
    // font-family: $font_main !important;
}

.MuiSvgIcon-fontSizeMedium {
    color: $color_secondary;
}