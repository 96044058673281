@import "../../styles/styles.scss";

.acquaintance {
    &__block {
        border: 1px solid $color_light_lines;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 50px 60px 60px 60px;

        h2 {
            text-align: center;
            margin: 0 auto 50px auto;
            display: block;
        }
    }

    &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            color: $color_main;
            font-size: 18px;
            line-height: 36px;
        }

        p:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    &__text {
        width: 60%;
    }

    &__blocks {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
    }

    &__small-block {
        border: 1px solid $color_light_lines;
        border-top: none;
        border-radius: 0 0 20px 20px;
        width: 20%;
        padding: 10px 15px;
        color: $color_main;
        font-size: 20px;
        font-weight: 700;
        line-height: 40px;
        text-align: center;
    }

    &__img {
        object-fit: contain;
        width: 30%;
        margin-right: 40px;
    }
}

@media (max-width: $lg) {
    .acquaintance {
        &__block {
            padding: 30px;
        }

        &__blocks {
            display: flex;
            justify-content: space-between;
            padding: 0 12px;
        }

        &__info {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;

            p {
                color: $color_main;
                font-size: 16px;
                line-height: 24px;
            }

            p:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        &__text {
            margin-top: 40px;
            width: 100%;
        }

        &__small-block {
            width: 32%;
            padding: 8px 13px;
            font-size: 16px;
            border-radius: 0 0 15px 16px;
        }

        &__img {
            width: 90%;
            margin-right: -20px;
        }
    }
}


@media (max-width: $xs) {
    .acquaintance {
        &__small-block {
            width: 31%;
            padding: 8px 10px;
            font-size: 15px;
            border-radius: 0 0 12px 12px;
        }
    }
}