@import "../../styles/styles.scss";

.header {
    background-color: $color_bg;
    position: fixed;
    z-index: 100;
    width: 100%;
    height: fit-content;
    padding: 10px 0;
    box-shadow: 0px 0px 8px 0px rgba(80, 90, 91, 0.20);

    &__link {
        color: $color_secondary;
        font-size: 17px;
        font-family: Montserrat;
        font-weight: 600;
        display: block;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    &__left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        a {
            width: fit-content;
            height: fit-content;
        }
    }

    &__logo {
        object-fit: contain;
        width: 80px;
        margin-bottom: 0;
    }

    &__button {
        @include primary-button(16px);
    }

    &__links {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__nav {
        height: fit-content;
        padding: 2px 0;
        background-color: $color_bg;

        width: 75%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__levels {
        white-space: nowrap;
    }

    &__levels-mobile {
        display: none;
    }
}


@media (max-width: $xl) {
    .header {
        &__links {
            width: 70%;
        }

        &__link {
            font-size: 16px;
        }
    }
}

@media (max-width: $lg) {
    .header {
        &__links {
            width: 80%;
        }
        
        &__link {
            // display: none;
        }

        &__levels {
            display: none;
        }

        &__levels-mobile {
            display: block;
            padding: 10px;
            white-space: nowrap;
            width: fit-content !important;
            height: fit-content !important;
        }

        &__nav {
            display: flex;
            justify-content: space-between;
        }

        // &__button {
        //     display: block;
        //     float: left;
        // }

        // &__links {
        //     display: flex;
        //     justify-content: right;
        // }

        &__button-order {
            display: none;
        }

    }
}

@media (max-width: $md) {
    .header {
        &__nav {
            width: 85%;
            padding: 10px 0;
        }
    }
}

@media (max-width: $sm) {
    .header {
        &__nav {
            width: 90%;
            padding: 3px 0;
        }

        &__links {
            width: 90%;
        }

        &__link {
            text-align: center;
            font-size: 12px;
            line-height: 13px;
        }

        &__button {
            width: 100px;
            font-size: 12px;
            padding: 3px;
        }

        &__logo {
            margin-right: 10px;
        }
    }
}


@media (max-width: $xs) {
    .header {
        &__logo {
            width: 50px;
        }

        &__link {
            text-align: center;
            font-size: 11px;
        }

        &__button {
            width: 100px;
            font-size: 11px;
            padding: 2px;
        }
        &__levels {
            padding: 8px;
            white-space: nowrap;
            width: fit-content !important;
            height: fit-content !important;
        }
    }
}