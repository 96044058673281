@import "../../styles/styles.scss";

svg {
    pointer-events: auto !important;
}

.board {
    margin-top: 50px;
    width: 538px;
    height: 548px;

    .circle {
        animation: moving 3s infinite linear;
        transition: 2s all;

        &:hover {
            cursor: pointer;
        }
    }
}

.hero {
    margin-bottom: 100px;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        text-align: right;
        font-size: 40px;
        font-weight: 900;
        letter-spacing: 1px;
        color: $color_secondary;
        margin: 20px 0 auto auto;

        span {
            text-decoration: underline;
        }
    }

    #hero__title {
        margin-top: -30px;
        display: block;
    }

    button {
        width: 100%;
        height: 100%;
    }

    &__click-circle {
        cursor: pointer;
    }

    &__photos {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__images {
        margin-top: 2%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 15px;

        img {
            object-fit: contain;
            width: 300px;
            border-radius: 10px;
        }
    }

    &__images_left {
        align-items: flex-start;
        opacity: 0;
        transition: all 0.9s;

        &.active {
            opacity: 1;
        }
    }

    &__images_right {
        align-items: flex-end;
        opacity: 0;
        transition: all 0.9s;

        &.active {
            opacity: 1;
        }
    }
}

@media (max-width: $xxl) {
    .board {
        margin-top: 50px;
        width: 438px;
        height: 448px;
    }

    .hero {
        &__images {
            img {
                object-fit: contain;
                width: 190px;
            }
        }
    }
}

@media (max-width: $xl) {
    .hero {
        margin-bottom: 100px;
        height: fit-content;

        &__images {
            gap: 20px;

            img {
                object-fit: contain;
                width: 200px;
                border-radius: 8px;
            }
        }

        h1 {
            font-size: 25px;
        }
    }

    .board {
        margin-top: 50px;
        width: 338px;
        height: 348px;
    }
}

@media (max-width: $lg) {
    .hero {
        margin-bottom: 100px;
        height: fit-content;

        &__images {
            gap: 20px;

            img {
                object-fit: contain;
                width: 180px;
                border-radius: 8px;
            }
        }

        &__image_3,
        &__image_6 {
            display: none;
        }

        h1 {
            font-size: 23px;
            margin: 20px 0 auto auto;
        }
    }

    .board {
        margin-top: 50px;
        width: 180px;
        height: 180px;
    }
}


@media (max-width: $sm) {
    .board {
        margin-top: 20px;
        width: 260px;
        height: 280px;

        &__hide {
            display: none;
        }
    }

    .hero {
        &__photos {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .hero__image_6,
            .hero__image_5,
            .hero__image_4,
            .hero__image_1 {
                display: none;
            }

            &.active {
                flex-direction: row;

                .hero__image_6,
                .hero__image_5,
                .hero__image_4,
                .hero__image_1 {
                    display: block;
                }
            }
        }

        &__images {
            margin-top: 2%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 15px;

            img {
                object-fit: contain;
                width: 175px;
                border-radius: 4px;
            }
        }

        &__images_left {
            align-items: flex-start;
            opacity: 0;
            transition: all 0.9s;

            &.active {
                opacity: 1;
                flex-direction: column;

            }
        }

        &__images_right {
            align-items: flex-end;
            opacity: 0;
            transition: all 0.9s;

            &.active {
                opacity: 1;
                flex-direction: column;
            }
        }

        &__image_2,
        &__image_3 {
            display: block;
        }

        #hero__title {
            margin-top: 0px;
        }
    }
}

@media (max-width: $xs) {
    .hero {
        margin-bottom: 140px;
    }
}


@keyframes moving {
    from {
        fill: rgba(217, 217, 217, 0.00);
    }

    50% {
        fill: rgba(217, 217, 217, 0.22);
    }

    to {
        fill: rgba(217, 217, 217, 0.00);
    }
}


.header {
    &__button-order-mobile {
        display: none;
    }
}

@media (max-width: $lg) {
    .header {
        &__button-order-mobile {
            margin-top: 10px;
            margin-bottom: 10px;
            display: flex;
            padding: 10px;
            white-space: nowrap;
            width: fit-content !important;
            height: fit-content !important;
            font-size: 12px;
        }
    }
}