@mixin transition() {
    transition: all .2s ease-in-out;
}

@mixin button() {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    font-weight: 500;
    @include transition();

    @media (max-width: $xl) {
        padding: 10px 14px;
        font-size: 16px;
    }
    @media (max-width: $md) {
        padding: 10px 12px;
        font-size: 16px;
    }
    @media (max-width: $sm) {
        padding: 8px 10px;
        font-size: 15px;
    }
}


@mixin primary-button($font-size) {
    @include button();
    font-size: $font-size;
    background-color: $color_secondary;
    color: $color_bg;

    &:hover {
        background-color: $color_secondary_hover;
    }
}

@mixin dark-button($font-size) {
    @include button();
    font-size: $font-size;
    background-color: $color_primary;
    color: $color_main;
}

@mixin grey-button($font-size) {
    @include button();
    font-size: $font-size;
    background-color: $color_bg_light;
    color: $color_main;

    &:hover {
        background-color: $color_bg_light_hover;
    }

    &:disabled {
        background-color: $background-shadow;
    }
}