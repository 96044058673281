// Colors 
$color_bg: #1A1A1A;
// $color_bg: #222222;
$color_bg_light: #2e2e2e;
$color_bg_light_hover: #0d0d0d;
$color_grey: rgb(187, 187, 187);
$background-shadow: #000000aa;

$color_main: #EFF1ED;
$color_secondary: #BCE2EE; 
$color_secondary_hover: #aad7e4; 
$color_tertiary: #E8EBE4;

$color_light_lines: rgba(255, 255, 240, 0.80);
$color_danger: #ec3f3f;


// Breakpoints
$xxl: 1400px; 
$xl: 1200px; 
$lg: 992px; 
$md: 768px; 
$sm: 576px; 
$xs: 480px; 

