@import "../../styles/styles.scss";


.modal {
    &-dialog {
        width: fit-content !important;
        max-width: fit-content !important;
    }

    &__submit {
        @include primary-button(16px);
    }

    &__cancel {
        @include grey-button(16px);
    }

    &__label {
        display: block;
        margin-bottom: 10px;
        margin-top: 10px;

    }

    input {
        font-family: $font_main;
    }

    li {
        font-family: $font_main;
        font-size: 16px;
    }

    #rate {
        font-family: $font_main;
    }

    &__input-error {
        font-size: 15px;
        margin-top: -5px;
        margin-bottom: 20px;
        color: $color_danger;
    }

    &__body {
        display: flex;
        flex-direction: row;
    }

    &__fields {
        margin-right: 50px;
    }

    &__logo {
        img {
            object-fit: contain;
            width: 330px;
        }
    }

    &__spinner {
        position: relative;

        .spinner {
            margin-left: -70px;
            width: 80px;
            height: 80px;
        }
    }

    &__result {
        margin: 15px;
        max-width: 90%;
    }
}

.MuiButtonBase-root {
    font-family: $font_main !important;
}

@media (max-width: $sm) {
    .modal {
        &-dialog {
            margin: auto !important;
            margin-top: 30px !important;
        }
        &-title {
            font-size: 17px;
            margin-right: 15px;
        }

        &__label {
            font-size: 15px;
        }

        &__body {
            flex-direction: column;
        }

        &__logo {
            margin: 0 auto;

            img {
                margin: 0 auto;
                object-fit: contain;
                width: 200px;
            }
        }
    }
}